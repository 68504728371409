import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Login from './components/Login';
import AgencyLogin from './components/AgencyLogin';
import Footer from './components/Footer';
import CartApp from './components/CartPage';
import Post from './components/Post';
import ApplicationForm from './components/Application';
import Form from './components/Form';
import RequestForm from './components/RequestForm';
import TalkwithLynn from './components/TalkwithLynn';
import CoursePay from './components/CoursePay';
import CheckoutPage from './components/CheckoutPage';
import PaymentPage from './components/PaymentPage';
import PaypalPayment from './components/PaypalPayment';
import PaypalPaymentNotify from './components/PaypalPaymentNotify';
import SezzlePaymentNotify from './components/SezzlePaymentNotify';
import ThankyouPage from './components/ThankyouPage'; 
import LocalStorage from './components/LocalStorage'; 
import ConferencePay from './components/ConferencePay';
import MiscPay from './components/MiscPay';
import ReenrollPay from './components/ReenrollPay';
import MiscellaneousCharge from './components/MiscellaneousCharge';
import AdvancedCertificate from './components/AdvancedCertificate';
import Store from './components/Store';
import ApparelProductDetail from './components/ApparelProductDetail';
import ViewTab from './components/ViewTab';
import EditProfile from './components/EditProfile';
import CreditCardChange from './components/CreditCardChange';
import CancelledSubscriptionCreditCardChange from './components/CancelledSubscriptionCreditCardChange';
import SubscriptionUpdate from './components/SubscriptionUpdate';
import CancelledSubscriptionUpdate from './components/CancelledSubscriptionUpdate';
import OrderDetailsPage from './components/OrderDetailsPage';
import ForgetPassword from './components/ForgetPassword';
import VerifyPassword from './components/VerifyPassword';
import Logout from './components/Logout';

const App = () => {
  return (
    <Router>
      <div>
        <Header />
        <Routes>
          <Route path="/user" element={<Login />} />
          <Route path="/agency/login" element={<AgencyLogin />} />
          <Route path="/smww/cart" element={<CartApp />} />
          <Route path="/post/:id" element={<Post />} />
          <Route path="/application" element={<ApplicationForm />} /> 
          <Route path="/form" element={<Form />} />
          <Route path="/request-more-info" element={<RequestForm />} />
          <Route path="/talk-with-dr-lynn-lashbrook" element={<TalkwithLynn />} />
          <Route path='/smww_pay' element={<CoursePay />} />
          <Route path='/smww/checkout' element={<CheckoutPage />} />
		      <Route path='/smww/checkout/cart' element={<PaymentPage />} />
          <Route path='/smww/checkout/paypal' element={<PaypalPayment />} />
          <Route path='/smww/checkout/paypal/notify' element={<PaypalPaymentNotify />} />
          <Route path='/smww/checkout/sezzle/confirm' element={<SezzlePaymentNotify />} />
		      <Route path='/smww/checkout/thankyou/:order_id' element={<ThankyouPage />} />
          <Route path='/smww/get/userorder' element={<LocalStorage />} />
          <Route path='/smww_conference_pay' element={<ConferencePay />} />
          <Route path='/smww_misc_pay' element={<MiscPay />} />
          <Route path='/smww_reenroll_pay' element={<ReenrollPay />} />
          <Route path='/miscellaneous/charge/:uid' element={<MiscellaneousCharge />} />
          <Route path='/advanced_certificates' element={<AdvancedCertificate />} />
          <Route path='/store' element={<Store />} />
          <Route path='/store/:slug' element={<ApparelProductDetail />} />
          <Route path='/user/:uid' element={<ViewTab />} />
          <Route path='/users/:username' element={<ViewTab />} />
          <Route path='/user/:uid/:tab' element={<ViewTab />} />
          <Route path='/subscription/card/change/:subscription_id' element={<CreditCardChange />} />
          <Route path='/cancelled_subscription/card/change/:subscription_id' element={<CancelledSubscriptionCreditCardChange />} />
          <Route path='/subscription/update/:subscription_id' element={<SubscriptionUpdate /> } />
          <Route path='/cancelled_subscription/update/:subscription_id' element={<CancelledSubscriptionUpdate /> } />
          <Route path='/user/:uid/smww_orders/:order_id' element={<OrderDetailsPage /> } />
          <Route path='/user/password' element={<ForgetPassword /> } />
          <Route path='/user/verify_password' element={<VerifyPassword /> } />
          <Route path='/user/logout' element={<Logout /> } />
          
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
