import React, { useState, useEffect } from 'react';
import './classes/CartPage.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { configData } from "../config";
import { Link, useNavigate,useLocation } from 'react-router-dom';
import MinicartLength from './MinicartLength';
import ReactDOM from "react-dom/client";
import Loading from './Loading';

const initialCart = [];

const initialpaymentOptions = [
  {
    name: 'Single Payment',
    value: '1'
  },
  {
    name: 'Two Payments',
    value: '2'
  },
  {
    name: 'Three Payments',
    value: '3'
  },
];


function CartPage() {
  const [cart, setCart] = useState(initialCart);
  const [paymentOptions, setpaymentOptions] = useState(initialpaymentOptions);
  const [discountlist, setDiscountList] = useState([]);
  const [classroomlist, setClassroomList] = useState([]);
  const [classroom, setClassroom] = useState([]);
  const [message, setMessage] = useState('');
  const [producttypes, setProductTypes] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const app_url = configData.APP_URL;
  const location  = useLocation();
  const msg = location.state;
  const [errors, setErrors] = useState([]);
  const order_id = localStorage.getItem('order_id');
  useEffect(() => {
   
    retrieveCountryDiscounts();
    retrieveCartProducts();
    retrieveClassroomLists();
    retrieveOrderProductTypes();
    setTimeout(() => {
      setIsLoading(false);
    }, 800);
    if(msg) {
      setMessage(msg);
      window.history.replaceState({}, '');
      
    }
 },[order_id]);

  const handleRemove = async (index,sku,paytime) => {
    if (window.confirm('Do you want to remove this from your cart?')) {
    const postData = {
      line_item_id: index,
      order_id: localStorage.getItem('order_id'),
      sku : sku,
      paytime: paytime
      }
    const response = await fetch(app_url+'smwwcommercecart/removecartitem', {
      method: 'POST',
      body: JSON.stringify(postData),
      headers: {
        'Content-Type': 'application/json',
      },
      });
      const data = await response.json();
     if(data.data.result === "removed") {
      retrieveCartProducts();
      const root = ReactDOM.createRoot(document.getElementById("top-header-cart-icon"));
      root.render(<MinicartLength length={data.data.line_items.length} />);
      setMessage(data.data.msg);
     }
    }
  };
  
  const handlePaymentPlanChange = (index, newPaymentPlan) => {
    const newCart = [...cart];
    newCart[index].paytime = newPaymentPlan;
    setCart(newCart);
    console.log(newCart);
  };
  const handleClassroomChange = (index, newClassroom) => {
    const newCart = [...cart];
    newCart[index].classroom = newClassroom;
    setCart(newCart);
    console.log(newCart);
  };
  
  async function retrieveCartProducts() {
    const order_id = localStorage.getItem('order_id');
    if( order_id) {
    const response = await fetch(
      app_url+'smwwcommercepay/getuserorders?order_id='+order_id,
      {
        method: "GET",
  
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    const responsedata = await response.json();
    
    setCart(responsedata.data);
   
  }
  }
  async function retrieveSession() {
   
    const response = await fetch(
      app_url+'site/getuserordersession',
      {
        method: "GET",
  
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    const order_id = localStorage.getItem('order_id');
    const data = await response.json();
    const yii_session = data.data.session;
    if(!order_id) {
      if(yii_session) {
        localStorage.setItem('order_id', yii_session.order_id);
      }
    } else {
     
    }
  }
  async function retrieveClassroomLists() {
    const classroomlistresponse = await fetch(
      app_url+'smwwcommercepay/getclassroomlists',
      {
        method: "GET",
  
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    const classroomlistdata = await classroomlistresponse.json();
    setClassroomList(classroomlistdata.data.result);
   
    
  }
  async function retrieveOrderProductTypes() {
    const order_id = localStorage.getItem('order_id');
    const orderProductTypes = await fetch(
      app_url+'smwwcommercecheckout/getorderproducttypes?order_id='+order_id,
      {
        method: "GET",
  
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    const orderProductTypesdata = await orderProductTypes.json();
    setProductTypes(orderProductTypesdata.data.result);
    
    
  }
  const validateForm = (cart) => {
    let isValid = true;
    const errors = [];
    {cart.map((course, index) => (
      <>
      {classroomlist[course.sku] &&
      <>
       {!course.classroom && 
          errors.push({
            sku: course.sku,
            message: "Please select the classroom start date for "+classroomlist[course.sku]['course_name'],
          })
        }
      </>
      }
     
      </>
    ))}
    var arrayLength = errors.length;
    if(arrayLength > 0) {
    for (var i = 0; i < arrayLength; i++) {
      document.getElementById('select'+errors[i]['sku']).className += " show_error";
      alert(errors[i]['message']);
      isValid = false;
    }
  }
    return isValid;
  };
  const handleUpdatetoCart = async event => { 
    const isValid = validateForm(cart);
   if (isValid) {
    event.preventDefault();
    
    const postData = {
              newCart: cart,
              order_id: localStorage.getItem('order_id')
              }
                   
    
    const response = await fetch(app_url+'smwwcommercecart/updatetocart', {
      method: 'POST',
      body: JSON.stringify(postData),
      headers: {
        'Content-Type': 'application/json',
      },
      });
      const data = await response.json();
      if(data.data.result === "order_updated") {
        retrieveCartProducts();
        if(producttypes) {
         
          if(!producttypes.includes("1") && !producttypes.includes("9")  && !producttypes.includes("3") && !producttypes.includes("2")) {
            navigate("/smww/checkout/cart");
          } else {
            navigate("/smww/checkout");
          }
        }
       
      }
    }
  }
  async function retrieveCountryDiscounts() {
    const discountlistresponse = await fetch(
      app_url+'smwwcommercediscount/getdiscountlist',
      {
        method: "GET",
  
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    const discountlistdata = await discountlistresponse.json();
    const discountlist = discountlistdata.data.result;
   
    setDiscountList(discountlist);
    
  }
  return (
    <>
    {isLoading ? (
        <Loading />
      ) : (
    <div className="cart-page">
      
      <div className="cart-details">
      {message && (
          <div className="set-message-done">
            <span className="cart-message-icon message-icon"><i className="fa fa-check-circle"></i></span> {message}
          </div>
        )}
        
        {cart.length > 0 ? (
          <>
        <h2>YOUR CART</h2>
        <table className="cart-table">
          <thead>
            <tr>
              <th className="text-uppercase product-title">Product</th>
              <th className="text-uppercase">Price</th>
              <th className="text-uppercase">Remove</th>
            </tr>
          </thead>
          <tbody>
            
            {cart.map((item, index) => (
              <tr key={index}>
                <td>
                  {item.name}
                  <div className='cart-page-payment-text'>
                   
                  
                  {classroomlist[item.sku] &&
                  <><div>
                   <label className='cart-page-dropdowns'>Classroom Start Date:</label>
                    <select name="coursesClassroom" className='select-payment' id={'select'+item.sku} value={item.classroom}
                    onChange={(e) => handleClassroomChange(index, e.target.value)}
                    >
                       <option value="">Select A Classroom Date: </option>
                       <option value="0">I am not ready to pick a start date</option>
                       {classroomlist[item.sku]['startdate'].map((option, optIndex) => (
                        <option key={option} value={option}>
                        {option}
                        </option>
                        ))}
                    </select><br /><br />
                    </div></>
                  }
                  
                  <div>
                  {item.product_type == 1 &&
                  <><label className='cart-page-dropdowns'>Payment Plan: </label>

                    <select
                      className='select-payment'
                      value={item.paytime}
                      onChange={(e) => handlePaymentPlanChange(index, e.target.value)}
                    >
                      {paymentOptions.map((option,cindex) => {
                      if(discountlist[item.country]) {
                        if(option.value !== 3) {
                           return <option key={cindex} value={option.value}>
                          {option.name}
                          </option>
                       } 
                      }
                        else {
                        return <option key={cindex} value={option.value}>
                        {option.name}
                      </option>
                      }
                   
                      })} 
                    </select></>
                  }
                    </div>
                  </div>
                </td>
                <td>${item.calculated_amount.toLocaleString(undefined, {minimumFractionDigits: 2})}</td>
                <td>
                  <button className="remove-button" onClick={() => handleRemove(item.line_item_id,item.sku,item.paytime)}>
                  <FontAwesomeIcon icon={faTrashAlt} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <button className="checkout-button1" onClick={handleUpdatetoCart}>Checkout</button>
        <br />
        <div className="cart-note">
          *All prices on this page are in USD.
          <br />
          **Alumni, economic, and military discounts will be applied during the last step of checkout.
        </div>
        </>
        ) : (
          <div className="empty-cart-message">
            <h2 className='cart-is-empty'>Your Cart is Empty.</h2>
            <p>To continue with your purchase, you can select one of the following options:</p>
            <div className="empty-cart-buttons">
              <Link to="/smww_pay">
                <button className="redirect-button">Go to Courses</button>
              </Link>
              <Link to="/conferences">
                <button className="redirect-button">Go to Conferences</button>
              </Link>
            </div>
          </div>
        )}
      </div>

      {cart.length > 0 && (
      <div className="order-summary">
        <h2>Your Order</h2>
        <div className="order-details">
          <div className="order-item">
            <span>Product Items ({cart.length})</span>
            <span>${cart.reduce((total, item) => total + item.calculated_amount, 0).toLocaleString(undefined, {minimumFractionDigits: 2})}</span>
          </div>
          <div className="order-total">
            <span>TOTAL</span>
            <span>${cart.reduce((total, item) => total + item.calculated_amount, 0).toLocaleString(undefined, {minimumFractionDigits: 2})}</span>
          </div>
          <button className="checkout-button2" onClick={handleUpdatetoCart}>Checkout</button>
        </div>
      </div>
       )}
      
    </div>
  )}</>
  );
}

export default CartPage;
