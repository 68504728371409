import React, { useState, useEffect} from 'react';
import { configData } from "../config";
import { useNavigate, useSearchParams } from 'react-router-dom';
import MinicartLength from './MinicartLength';
import ReactDOM from "react-dom/client";

function MiscPay() {
    const[searchParams,setSearchParams] = useSearchParams();
    const order_id = localStorage.getItem('order_id');
    const lineitems = searchParams.getAll('lineitems');
    const [items, setItems] = useState({});
     
    const obj = {};

    // Using forEach to create key-value pairs
    lineitems.forEach((key, index) => {
        obj[key] = 1;
    });
    const app_url = configData.APP_URL;
    const navigate = useNavigate();

    useEffect(()=>{
        
        addtocart(order_id,obj);
    },[])

    async function addtocart(order_id,lineitems) {
        
        const postData = {
         lineitems: lineitems,
         country:'',
          order_id: order_id,
        }
        const response = await fetch(app_url+'smwwcommercepay/addtocart', {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: {
              'Content-Type': 'application/json',
            },
        });
        const data = await response.json();
        if(data.data.result === "order_created") {
            localStorage.setItem('order_id', data.data.order_id);
            localStorage.setItem('drupal_orderid', data.data.drupal_orderid);
            const root = ReactDOM.createRoot(document.getElementById("top-header-cart-icon"));
            root.render(<MinicartLength length={data.data.products.length} />);
            navigate("/smww/cart");
        } else {
               
        }
    }
}
export default MiscPay;