import React, { useState, useEffect} from 'react';
import { configData } from "../config";
import { useNavigate, useSearchParams } from 'react-router-dom';
import MinicartLength from './MinicartLength';
import ReactDOM from "react-dom/client";

function ReenrollPay() {
    const[searchParams,setSearchParams] = useSearchParams();
    const order_id = localStorage.getItem('order_id');
    const lineitems = searchParams.get('lineitems');
    const quantity  = searchParams.get("quantity");
    const courses_classroom = searchParams.get('courses_classroom');
    const courses_classroom_name = searchParams.get('courses_classroom_name');
    const courses_classroom_old = searchParams.get('courses_classroom_old');
    const courses_classroom_new = searchParams.get('courses_classroom_new');
    
    const [items, setItems] = useState({});
     
    const obj = {};

   
    const app_url = configData.APP_URL;
    const navigate = useNavigate();

    useEffect(()=>{
        
        addtocart(order_id,lineitems,quantity,courses_classroom,courses_classroom_name,courses_classroom_old,courses_classroom_new);
    },[])

    async function addtocart(order_id,lineitems,quantity,courses_classroom,courses_classroom_name,courses_classroom_old,courses_classroom_new) {
        const selectedOption = {[lineitems]: quantity};
        const selectedcourseclassroom = {[lineitems]: courses_classroom};
        const courseclassroom_name = {[lineitems]: courses_classroom_name};
        const courseclassroom_old = {[lineitems]: courses_classroom_old};
        const courseclassroom_new = {[lineitems]: courses_classroom_new};

        const postData = {
         lineitems: selectedOption,
         country:'',
         courses_classroom:selectedcourseclassroom,
         courses_classroom_name:courseclassroom_name,
         courses_classroom_old:courseclassroom_old,
         courses_classroom_new:courseclassroom_new,
          order_id: order_id,
        }
        const response = await fetch(app_url+'smwwcommercepay/addtocart', {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: {
              'Content-Type': 'application/json',
            },
        });
        const data = await response.json();
        if(data.data.result === "order_created") {
            localStorage.setItem('order_id', data.data.order_id);
            localStorage.setItem('drupal_orderid', data.data.drupal_orderid);
            const root = ReactDOM.createRoot(document.getElementById("top-header-cart-icon"));
            root.render(<MinicartLength length={data.data.products.length} />);
            navigate("/smww/cart");
        } else {
               
        }
    }
}
export default ReenrollPay;