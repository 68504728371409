import React, { useState, useEffect} from 'react';
import { configData } from "../config";
import { useNavigate, useSearchParams } from 'react-router-dom';


function Logout() {
   
    const app_url = configData.APP_URL;
    const agency_domain_url = configData.AGENCY_DOMAIN_URL;
    const scout_domain_url = configData.SCOUT_DOMAIN_URL;
    const drupal_domain_url = configData.DRUPAL_DOMAIN_URL;
    var loggedin_domain = drupal_domain_url;
    if(localStorage.getItem('loggedin_domain')) {
      loggedin_domain = localStorage.getItem('loggedin_domain');
    } 
    useEffect(()=>{
        
        autologout();
    },[])
    const navigate = useNavigate();
    async function autologout() {
        
        const response = await fetch(
            app_url+'site/logout?action='+"logout",
            {
              method: "GET",
        
              headers: {
                'Content-Type': 'application/json',
              },
            }
          )
            const data = await response.json();
            const resultdata = data.data;
            if(resultdata.msg === "Success") {
              
              localStorage.removeItem('uid');
              localStorage.removeItem('username');
              localStorage.removeItem('email');
              localStorage.removeItem('drupal_uid');
              localStorage.removeItem('session_id');
              localStorage.removeItem('order_id');
              localStorage.removeItem('shippingfields');
              localStorage.removeItem('sel_country');
              localStorage.removeItem('loggedin_domain');
              if(resultdata.application_user === true) {
                //navigate("/user");
                const drupal_domain_url = configData.DRUPAL_DOMAIN_URL;
                window.location.href = loggedin_domain+'/api/user/logout';
              } else {
                const drupal_domain_url = configData.DRUPAL_DOMAIN_URL;
                window.location.href = loggedin_domain+'/api/user/logout';
                
                
              }
              
            } 
    }
}
export default Logout;