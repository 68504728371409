import { useState, useEffect, useRef} from 'react';
import './classes/ReferralPage.css';
import { configData } from "../config";
import ReferralEmail from './ReferralEmail';
import Terms from './Terms';

const ReferralPage = (uid) => {
  uid = uid.uid;
  const [activeTab, setActiveTab] = useState('Overview');
  const [activeSubTab, setActiveSubTab] = useState('Social');
  const [coupon, setCouponName] = useState('');
  const [customcoupon, setCustomCouponName] = useState('');
  const [sharelink, setShareLink] = useState('');
  const [amount, setCouponAmount] = useState('');
  const [emailcontent, setEmailContent] = useState('');
  const [subject, setEmailSubject] = useState('');
  const [user, setUser] = useState('');
  const[error,setError] = useState({});
  const[success,setSuccess] = useState({});
  const[showcustomcoupon,setShowCustomCoupon] = useState(0);
  const [pendinguser, setPendingUser] = useState([]);
  const [paiduser, setPaidUser] = useState([]);
  const[referralbalance,setReferralBalance] = useState('');
  const loggedin_uid = localStorage.getItem('uid');
  const app_url = configData.APP_URL;

  useEffect(() => {
   
    retrieveCoupondetails();
    
  },[]);

  async function retrieveCoupondetails() {
    if(uid) {
    const response = await fetch(
      app_url+'referral/getreferraldetails?uid='+uid,
      {
        method: "GET",
  
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    const responsedata = await response.json();
    if(responsedata.data.coupon) {
      setCouponName(responsedata.data.coupon)
      setCustomCouponName(responsedata.data.coupon)
    }
    if(responsedata.data.share_link) {
      setShareLink(responsedata.data.share_link)
    }
    if(responsedata.data.amount) {
      setCouponAmount(responsedata.data.amount)
    }
    if(responsedata.data.email_content) {
      setEmailContent(responsedata.data.email_content)
    }
    if(responsedata.data.subject) {
      setEmailSubject(responsedata.data.subject)
    }
    if(responsedata.data.subject) {
      setUser(responsedata.data.user)
    }
    if(responsedata.data.bonus_amount) {
      setReferralBalance(responsedata.data.bonus_amount);
    }
    if(responsedata.data.custom_coupon_generated) {
    setShowCustomCoupon(responsedata.data.custom_coupon_generated)
    }
    setPendingUser(responsedata.data.pending_users)
    setPaidUser(responsedata.data.paid_users)
  }
  }

  const handleCustomCoupon  = async event => {
    event.preventDefault();
    const postData = {
      customcoupon: customcoupon,
      uid:uid
    }
      const response = await fetch(app_url+'referral/generatecustomcoupon', {
      method: 'POST',
      body: JSON.stringify(postData),
      headers: {
        'Content-Type': 'application/json',
      },
      });
      const data = await response.json();
      if(data.data.msg === "Error") {
        setError(data.data.result);
        window.scrollTo(0, 0);
      }
      if(data.data.msg === "Success") {
        setSuccess(data.data.result);
        retrieveCoupondetails();
        window.scrollTo(0, 0);
      }
  }
  return (
    <div className="referral-page">
      {loggedin_uid && !Number.isNaN(loggedin_uid) ? (
      <><p className="head_title">SMWW Referral Wallet</p>
      {error.length > 0 && (
          <div className="set-message-error">
           {error.map((item, index) => {
           return <>{item}<br /></>
          
        })}  
          </div>
        )}
         {success.length > 0 && (
          <>
           {success.map((item, index) => {
             return <>
            <div className="set-message-done">
            <span className="cart-message-icon message-icon"><i className="fa fa-check-circle"></i></span> {item}
          </div></>
          
        })}  
          </>
        )}
      <div className="tabs">
        <button
          className={activeTab === 'Overview' ? 'active' : ''}
          onClick={() => setActiveTab('Overview')}
        >
          Overview
        </button>
        <button
          className={activeTab === 'Share & Earn' ? 'active' : ''}
          onClick={() => setActiveTab('Share & Earn')}
        >
          Share & Earn
        </button>
        <button
          className={activeTab === 'My Balance' ? 'active' : ''}
          onClick={() => setActiveTab('My Balance')}
        >
          My Balance
        </button>
        <button
          className={activeTab === 'Terms & Conditions' ? 'active' : ''}
          onClick={() => setActiveTab('Terms & Conditions')}
        >
          Terms & Conditions
        </button>
      </div>

      {activeTab === 'Overview' && (

        <div className='overview-container'>
          <p>The SMWW Referral Wallet allows you to take ${amount} off of your next 
            conference, course, apprenticeship purchase for every student you refer to SMWW.</p>
        <div className='overview-hr' />    

        <div className="commonHeading">Here's how it works</div>

        <div className="overview">

          <div className="overview-item">
            <h2>1</h2>
          <div className="s_box">
            <ul>
              <li>
              <img src={`${process.env.PUBLIC_URL}/images/refer/box-invite.png`} alt="Invite Friends" />
              </li>
            </ul>
            
          </div>  
            <div className="invit">Invite Your Friends!</div>
            <div className="description">
              Send your SMWW Referral link to your friends via social media or email.
            </div>
          </div>


          <div className="overview-item">
          <h2>2</h2>
          <div className="s_box">
            <ul>
              <li>
              <img src={`${process.env.PUBLIC_URL}/images/refer/box-earn.png`} alt="Earn" />
              </li>
            </ul>
            
          </div> 
            <div className="earn">Earn Credit!</div>
            <div className="description">
              Earn Credit when you refer a friend.
            </div>
          </div>


          <div className="overview-item">
          <h2>3</h2>
          <div className="s_box">
            <ul>
              <li>
              <img src={`${process.env.PUBLIC_URL}/images/refer/box-refer.png`} className='redeem-image' alt="Redeem Balance" />
              </li>
            </ul>
            
          </div>       
            <div className="redeem">Redeem Your Referral Wallet Balance!</div>
            <div className="description">
              Your Referral Wallet balance will be automatically added to your next purchase. Please make sure to login to redeem it.
            </div>
          </div>

        </div>
        </div>
      )}

      {activeTab === 'Share & Earn' && (
        <div className="share-earn">
          <div className="sub-tabs">
            <button
              className={activeSubTab === 'Social' ? 'active' : ''}
              onClick={() => setActiveSubTab('Social')}
            >
              Social
            </button>
            <button
              className={activeSubTab === 'Email' ? 'active' : ''}
              onClick={() => setActiveSubTab('Email')}
            >
              Email
            </button>
          </div>
          

          {activeSubTab === 'Social' && (
            <div className="social-container">
              <div className='share-refr-Social'>
              <a target="_blank" href={"https://www.facebook.com/sharer.php?u="+sharelink+"&quote=Save $"+amount+" when you use coupon code "+coupon+" at SMWW. Click here to get started! "+sharelink
              }><img src={`${process.env.PUBLIC_URL}/images/refer/share-facebook.png`} alt="" /></a>
              <a target="_blank" href={"https://twitter.com/intent/tweet?text=Save $"+amount+" when you use coupon code "+coupon+" at SMWW. Click here to get started!&url="+sharelink}><img src={`${process.env.PUBLIC_URL}/images/refer/share-twitter.png`} alt="" /></a>
              <a target="_blank" href={"https://www.linkedin.com/sharing/share-offsite?url="+sharelink+"&summary=Save "+amount+" when you use coupon code "+coupon+" at SMWW. Click here to get started!"}><img src={`${process.env.PUBLIC_URL}/images/refer/share-linkedin.png`} alt="" /></a>
              </div>
            </div>
          )}

        {activeSubTab === 'Email' && (
            <div className="email">
              <ReferralEmail email_content={emailcontent} subject={subject} user={user}/>
            </div>
          )}

          <div className='social-copyLink'>
            <p>OR, copy and paste the link below and share it anywhere you want.</p>
            <input type="text" value={sharelink} readOnly />
            <p style={{textAlign:'left'}}>*You may not refer your self.</p>
          </div>  

          <div className='refr-cust-hr'></div>
          {showcustomcoupon === 0 &&
          <div className="customize-code">

            <h2>Customize Your Referral Code</h2>
            <p style={{textAlign:'center'}}>You can customize your SMWW Referral Wallet coupon code here:</p>

            <div className='cust-reff-code'>
              <input type="text" defaultValue={coupon} name="customCoupon" onChange={(e) => setCustomCouponName(e.target.value)}/>
              <button onClick={handleCustomCoupon}>Create</button>
            </div>

            <p style={{fontSize:'13px'}}>*The coupon code must be alphanumeric, between 5 and 16 characters (no spaces).
              <b>Once created, your customized coupon code cannot be changed.</b></p>
          </div>
        }
        </div>
      )}

      {activeTab === 'My Balance' && (
        <>
        <div className="my-balance">

          <div className='my_balance_container'>
          <div>
          <p className='statsTitle'>Here’s your SMWW Referral Wallet balance</p>
          <p><b>Referral Balance:</b> ${referralbalance}</p>
          </div>  
          </div>

          <div className='balance-panel-default'>
            <p><b>Users who were referred but have not paid for a course:</b></p>
            <table>
            {pendinguser.length > 0 && (
              <>
                {pendinguser.map((item, index) => (
                  <tr>
                    <td><a href={"/user/"+item.uid}>{item.email}</a></td>
                  </tr>
                        
                ))}
              </>
            )}

            <p><b>Users who were referred and paid for a course:</b></p>
            {paiduser.length > 0 && (
              <>
                {paiduser.map((item, index) => (
                  <tr>
                    <td><a href={"/user/"+item.uid}>{item.email}</a></td>
                  </tr>
                        
                ))}
              </>
            )}
             </table>
          </div>
         

        <p className='ref-bal-desc'>Your Referral Wallet balance will be automatically added to your next \
          purchase. Please make sure you’re logged in before making your next purchase.</p>
        </div>
        </>
      )}

      {activeTab === 'Terms & Conditions' && (
        <div className="terms-conditions">
          <Terms />
        </div>
      )}
      </>
    ):(
      <><p>You are not authorized.</p></>
    )
  }
    </div>
  );
};

export default ReferralPage;
